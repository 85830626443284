import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { List, Grid, ListItem, ListItemIcon, ListItemText, Button, Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import Loading from '../Loading/Loading';
import {
  TASK_ICONS,
  REWARD_ICONS,
} from '../Task';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Task Button Styling
const StyledTaskButton = styled(Button)(({ theme, active = false }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '6.5vh',
  width: '26%',
  padding: active ? '12px 18px' : '12px 16px',
  backgroundColor: active ? '#3d04c7' : '#5529ec',
  backgroundImage: active 
    ? 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)' 
    : '#5529ec',
  boxShadow: `
    inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
    inset 0 -4px 4px 1px #c5b3ff,
    inset 0 0 0px 8px rgba(255, 255, 255, 0.2),
    0 1px 4px 1px rgba(56, 41, 236, 0.2),
    0 1px 3ax 1px #5529ec
  `,
  textShadow: active ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  fontSize: active ? '14px' : '13px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  filter: active ? 'hue-rotate(300deg)' : 'none',
  '&:hover': {
    transform: 'scale(1.09)',
    filter: 'hue-rotate(300deg)',
  },
  '&:active': {
    transform: 'scale(0.96)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3) !important',
  }
}));

// List Item Styling
const StyledListItem = styled(ListItem)(({ theme }) => ({
  background: '#00000086',
  backdropFilter: 'blur(4px)',
  borderRadius: '40px',
  marginBottom: '8px',
  marginLeft: '8px',
  padding: '0',
  width: '94vw',
  height: '10vh',
  maxHeight: '15vh',
  paddingRight: '8px',
  paddingLeft: '12px',
  animation: `${fadeIn} 0.5s ease-in-out`,
  transition: 'background-color 0.3s ease, opacity 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 25, 50, 0.8)',
  }
}));

const TaskList = memo(({ tasks, taskType = "basic", showSnackbar, onTaskSelect, userProfileInfo }) => {
  const completedTasks = userProfileInfo?.taskProgress?.completed;

  const renderRewards = useCallback((task) => {
    const hasDiamondRewards = task.diamonds > 0;
    
    return (
      <>
        {hasDiamondRewards && (
          <>
            <img 
              src={REWARD_ICONS.DIAMOND} 
              alt="Diamond" 
              style={{ width: '18px', marginRight: '2px', verticalAlign: 'middle' }} 
            />
            {`${task.diamonds}`}
            &nbsp;&nbsp;
          </>
        )}
        <img 
          src={REWARD_ICONS.COIN} 
          alt="Points" 
          style={{ width: hasDiamondRewards ? '16px' : '17px', marginRight: '2px', verticalAlign: 'middle' }} 
        />
        {`${task.points}`}
      </>
    );
  }, []);

  const handleTaskClick = useCallback((task) => {
    if (!completedTasks) {
      showSnackbar('Please log in to perform tasks.', 'error');
      return;
    }

    if (completedTasks.includes(task.taskId)) {
      showSnackbar('You have already completed this task!', 'info');
      return;
    }

    const totalReferrals = userProfileInfo?.referral?.totalReferrals || 0;
    if (task.category === 'Referral' && totalReferrals < task.title.replace(/[^0-9]/g, '')) {
      showSnackbar(`You need ${Number(task.title.replace(/[^0-9]/g, '')) - totalReferrals} friends to complete this task.`, 'error');
      return;
    }

    onTaskSelect(task.taskId);
  }, [completedTasks, showSnackbar, onTaskSelect, userProfileInfo]);

  const getFilteredTasks = useCallback(() => {
    if (!tasks?.length) return [];
  
    const filteredTasks = tasks.filter(task => {
      const typeMatch = task.type?.toLowerCase() === taskType?.toLowerCase();
      const isCompleted = completedTasks?.includes(task.taskId);
      // if task has disabled field and its value is true, return false and if user is tester show task (even if disabled is true)
      const isTester = process.env.REACT_APP_NODE_ENV !== 'production';
      const disabled = task?.disabled === true && !isTester;
      return typeMatch && !isCompleted && !disabled;
    });

    // Sort tasks to put TON tasks first
    return filteredTasks.sort((a, b) => {
      // If a is TON task and b is not, a should come first
      if (a.category === 'TON' && b.category !== 'TON') return -1;
      // If b is TON task and a is not, b should come first
      if (b.category === 'TON' && a.category !== 'TON') return 1;
      // Otherwise keep based on date
      // First convert date to milliseconds
      const aDate = new Date(a.createdAt).getTime();
      const bDate = new Date(b.createdAt).getTime();
      // then compare
      if (aDate > bDate) return -1;
      if (bDate > aDate) return 1;
      return 0;
    });
  }, [tasks, taskType, completedTasks]);
  
  const renderTaskItem = useCallback((task) => {
    const completed = completedTasks?.includes(task.taskId);
    const totalReferrals = userProfileInfo?.referral?.totalReferrals || 0;
    const disabledReferralTask = task.category === 'Referral' && totalReferrals < task.title.replace(/[^0-9]/g, '');
    const taskIcon = TASK_ICONS[task.category];

    // if user profile info is 1887509957 (tester account) show task (even if disabled is true) but don't show in production
    const specialTasks = ['314781f1-16f0-4858-a010-aad9d1b55fbb', '4fb89620-80c7-4046-b975-d775b8218c52'];
    const bannedUsers = ['672826039'];

    // Ban special tasks for some users
    if (specialTasks.includes(task.taskId) && !bannedUsers.includes(userProfileInfo?.userId)) {
      return null;
    }

    return (
      <StyledListItem 
        key={task.taskId}
        onClick={() => handleTaskClick(task)}
        style={{ 
          opacity: completed ? 0.4 : 1,
          cursor: completed ? 'default' : 'pointer'
        }}
      >
        <ListItemIcon sx={{ mr: 1 }}>
          {taskIcon && <img 
            src={task?.icon || taskIcon} 
            alt={task.category} 
            width={task?.icon? "53px" : "60px"} 
            height={task?.icon? "53px" : "60px"} 
            style={{ borderRadius: '35%', marginLeft: task?.icon ? '5px' : 0, 
                transform: task?.category === 'TON' ? 'scaleX(-1.05)' : 'scale(1)',
                rotate: task?.category === 'TON' ? '15deg' : '0deg'  
            }}
          />}
        </ListItemIcon>

        <ListItemText 
          primary={`${task.title} ${task.category === "Referral" ? `(${totalReferrals}/${task.title.replace(/[^0-9]/g, '')})` : ''}`}
          secondary={renderRewards(task)}
          primaryTypographyProps={{ 
            fontSize: '14.5px', 
            fontFamily: 'Sora, sans-serif', 
            fontWeight: 'bold', 
            color: 'white', 
            lineHeight: '18px' 
          }}
          secondaryTypographyProps={{ 
            fontFamily: 'Poppins-Medium, sans-serif', 
            color: 'white' 
          }}
          sx={{ width: '85%' }}
        />

        <StyledTaskButton 
          disabled={completed || disabledReferralTask}
          active={!completed}
          onClick={(e) => {
            e.stopPropagation();
            handleTaskClick(task);
          }}
        >
          {completed ? 'Done' : 'Start'}
        </StyledTaskButton>
      </StyledListItem>
    );
  }, [completedTasks, handleTaskClick, renderRewards, userProfileInfo]);

  if (!tasks?.length) {
    return <Loading loading={true} customStyle={{ left: '40vw', top: '45vh'}} />;
  }

  const filteredTasks = getFilteredTasks();

  if (filteredTasks.length === 0) {
    return (
      <Box
        sx={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: 'white',
          fontFamily: 'Sora, sans-serif',
          padding: '0 20px'
        }}
      >
        <Typography 
          sx={{ 
            fontSize: '16px',
            opacity: 0.8,
            fontWeight: 'medium' 
          }}
        >
          Great job! You've completed all available tasks. Check back later for more opportunities.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid 
        container 
        spacing={2} 
        pt={2} 
        sx={{ 
          height: '60vh',
          overflowY: 'scroll',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignContent: 'space-between',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        <List sx={{ alignItems: 'center', alignContent: 'center' }}>
          {filteredTasks.map(renderTaskItem)}
        </List>
      </Grid>
    </>
  );
});

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    taskId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    diamonds: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    completionsCount: PropTypes.number
  })).isRequired,
  taskType: PropTypes.string,
  completedTasks: PropTypes.arrayOf(PropTypes.string),
  showSnackbar: PropTypes.func.isRequired,
  onTaskSelect: PropTypes.func.isRequired,
  userAddress: PropTypes.string.isRequired,
  userProfileInfo: PropTypes.shape({
    taskProgress: PropTypes.shape({
      lastCompletedAt: PropTypes.string,
      completed: PropTypes.arrayOf(PropTypes.string),
    }),
    referral: PropTypes.shape({
      totalReferrals: PropTypes.number
    })
  }).isRequired
};

TaskList.displayName = 'TaskList';

export default TaskList;
import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Send, Reply } from 'lucide-react';
import ProgressBar from './ProgressBar';

// Keyframes for animations
const slideInTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOutLeft = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const slideInRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Styled Components
const AnimatedImage = styled(Box)`
  animation: ${props => props.isExiting ? slideOutLeft : slideInRight} 0.5s ease-out forwards;
`;

const AnimatedContent = styled(Box)`
  animation: ${props => props.isExiting ? slideOutLeft : slideInRight} 0.5s ease-out forwards;
`;

const TipMethodPage = ({ nextPage, backgroundSource }) => {
  const [currentMethod, setCurrentMethod] = useState(1);
  const [isExiting, setIsExiting] = useState(false);

  const handleNext = () => {
    if (currentMethod === 1) {
      setIsExiting(true);
      setTimeout(() => {
        setCurrentMethod(2);
        setIsExiting(false);
      }, 500);
    } else {
      nextPage();
    }
  };

  const methodContent = {
    1: {
      image: "https://i.ibb.co/xJ5M4V3/i-Phone-13-PRO-localhost-2-1-min.png",
      title: "Easy Tipping",
      subtitle: "Method 1",
      icon: <Send size={30} color="#FF6D00" style={{ animation: `${floatAnimation} 2s ease-in-out infinite` }} />,
      steps: [
        "• Just click on the tip button",
        "• Search username and select user",
        "• Set amount and click Send Tip, Done!"
      ],
      buttonText: "Next Method"
    },
    2: {
      image: "https://i.ibb.co/p3YRB5G/i-Phone-13-PRO-web-telegram-org-1-min.png",
      title: "Quick Reply Tip",
      subtitle: "Method 2",
      icon: <Reply size={30} color="#FF6D00" style={{ animation: `${floatAnimation} 2s ease-in-out infinite` }} />,
      steps: [
        "• Reply to any message in group",
        "• Type /tip followed by amount",
        "• Example: /tip 20"
      ],
      buttonText: "Got it!"
    }
  };

  const current = methodContent[currentMethod];

  return (
    <Box
      sx={{
        width: '100vw', 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#ebe9ff',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ProgressBar step={4} />
      
      <AnimatedImage
        isExiting={isExiting}
        sx={{
          width: '350px',
          height: '725px',
          position: 'absolute',
          top: '-50vh',
          left: '6vw',
          zIndex: 2,
          backgroundImage: `url('${current.image}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />

      <AnimatedContent
        isExiting={isExiting}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: '35vh',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontFamily="Sora, sans-serif"
          fontWeight="800"
          sx={{ 
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          {current.title}
          {current.icon}
        </Typography>

        <Typography
          variant="body1"
          align="center"
          fontFamily="Sora, sans-serif"
          fontWeight="800"
          sx={{ color: 'black', pt: 1 }}
        >
          {current.subtitle}
        </Typography>

        <Box
          sx={{
            width: '85vw',
            textAlign: 'left',
            px: 2,
            py: 1.5
          }}
        >
          {current.steps.map((step, index) => (
            <Typography
              key={index}
              variant="body1"
              fontFamily="Sora, sans-serif"
              fontWeight="500"
              sx={{
                color: 'black',
                mb: 1,
                '& .highlight': {
                  color: '#FF6D00',
                  fontWeight: 'bold'
                }
              }}
            >
              <span className="highlight">{step.split(" ")[0]}</span>
              {" " + step.split(" ").slice(1).join(" ")}
            </Typography>
          ))}
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ 
            mx: 'auto', 
            width: '85vw', 
            mb: 2, 
            py: 1, 
            fontSize: '1.15rem', 
            fontFamily: 'Sora, sans-serif', 
            fontWeight: 'bold', 
            borderRadius: '20px', 
            textTransform: 'capitalize', 
            zIndex: 9,
            backgroundColor: '#5529ec',
            backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
            boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), 
                       inset 0 -4px 4px 1px #c5b3ff, 
                       inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 
                       0 1px 4px 1px rgba(56, 41, 236, 0.2), 
                       0 1px 3px 1px #5529ec`,
            '&:hover': {
              transform: 'scale(1.04)',
              backgroundColor: '#4a1eec'
            }
          }}
        >
          {current.buttonText}
        </Button>
      </AnimatedContent>
    </Box>
  );
};

export default TipMethodPage;
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { shopApi, userApi } from '../../services/api';
import { formatNumber } from '../../utils/Formatters';
import { useAdsgram } from '../../services/useAdsgram';


const WebApp = window.Telegram.WebApp;

const PopupContainer = styled(Box)(({ theme, heightSize = '55vh' }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: heightSize,
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTopLeftRadius: '35px',
  borderTopRightRadius: '35px',
  borderTop: '5px solid orange',
  transform: 'translateY(100%)',
  transition: 'transform 0.3s ease-in-out',
  zIndex: 999,
  color: 'white'
}));

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: '10rem',
  textTransform: 'none',
  height: '7vh',
  width: '80%',
  padding: '12px 16px',
  backgroundColor: disabled ? 'rgba(85, 41, 236, 0.5)' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
              inset 0 -4px 4px 1px #c5b3ff,
              inset 0 0 0px 8px rgba(255, 255, 255, 0.2)`,
  color: 'white',
  fontSize: '17px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  transition: 'all 0.2s ease-in-out',
  opacity: disabled ? 0.7 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    backgroundColor: disabled ? 'rgba(85, 41, 236, 0.5)' : '#4a20e3',
    transform: disabled ? 'none' : 'scale(1.02)'
  }
}));

function ShopPurchasePopup({ 
  item, 
  open, 
  onClose, 
  userProfileInfo,
  setUserProfileInfo,
  showSnackbar 
}) {
  const [loading, setLoading] = useState(false);

  // Handle Stars payment
  const handleStarPayment = async () => {
    try {
      setLoading(true);
      
      const response = await shopApi.generateInvoiceLink({
        itemId: item._id,
        userId: userProfileInfo.userId
      });
  
      if (response.data.success) {
        WebApp.openInvoice(response.data.invoiceLink, async (status) => {
          console.log('Invoice status:', status);
          if (status === "paid") {
            try {
              // Wait a bit longer for webhook processing
              await new Promise(resolve => setTimeout(resolve, 2000));
              
              // Get updated user data
              const userResponse = await userApi.getUserData(userProfileInfo.userId);
              if (userResponse.data) {
                setUserProfileInfo(userResponse.data);
                showSnackbar('Purchase successful!', 'success');
                onClose();
              } else {
                showSnackbar('Purchase completed. Refreshing...', 'info');
                // Try to refresh one more time after a delay
                setTimeout(async () => {
                  try {
                    const retryResponse = await userApi.getUserData(userProfileInfo.userId);
                    if (retryResponse.data) {
                      setUserProfileInfo(retryResponse.data);
                    }
                  } catch (error) {
                    console.error('Final retry error:', error);
                  }
                }, 3000);
              }
            } catch (error) {
              console.error('Error updating user data:', error);
              showSnackbar('Purchase completed. Please refresh the app to see your updated balance.', 'info');
            }
          } else if (status === "cancelled") {
            showSnackbar('Payment was cancelled', 'info');
          } else if (status === "failed") {
            showSnackbar('Payment failed', 'error');
          }
          setLoading(false);
        });
      }
    } catch (error) {
      console.error('Payment error:', error);
      showSnackbar(error?.response?.data?.error || 'Failed to process payment', 'error');
      setLoading(false);
    }
  };

  // Handle in-app currency purchase
  const handleInAppPurchase = async () => {
    if (loading || !item?._id) return;
    
    setLoading(true);
    try {
      const response = await shopApi.purchaseItem({
        userId: userProfileInfo.userId,
        itemId: item._id // This should now be available
      });
  
      if (response.data.success) {
        const message = item.category === 'premium' 
          ? 'Premium features activated successfully!'
          : item.category === 'diamond'
          ? `${item.rewards.diamonds} Gems added to your account!`
          : 'Purchase successful!';
  
        showSnackbar(message, 'success');
        setUserProfileInfo(response.data.user);
        onClose();
      }
    } catch (error) {
      console.error('Purchase error:', error);
      showSnackbar(
        error?.response?.data?.error || 
        'Insufficient funds to complete purchase', // More specific error message
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  
  // Handle ad reward
  const onAdReward = useCallback(() => {
    handleInAppPurchase();
  }, [handleInAppPurchase]);

  const onAdError = useCallback(() => {
    showSnackbar('Failed to play ad. Please try again.', 'error');
    setLoading(false);
  }, [showSnackbar]);

  const showAd = useAdsgram({ onReward: onAdReward, onError: onAdError });

  const handleAction = useCallback(() => {
    if (item.adRequired) {
      showAd();
    } else if (item.currency === 'XTR') {
      handleStarPayment();
    } else {
      handleInAppPurchase();
    }
  }, [item, showAd, handleStarPayment, handleInAppPurchase]);

  return (
    <PopupContainer heightSize={item?.category === 'diamond' ? '44vh' : '58vh'} sx={{ transform: open ? 'translateY(0)' : 'translateY(100%)' }}>
      <IconButton 
        onClick={onClose}
        sx={{ position: 'absolute', right: 16, top: 16, color: 'white' }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <Box
          component="img"
          src={item?.icon}
          alt={item?.name}
          sx={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            marginBottom: '20px'
          }}
        />

        <Typography 
          fontSize="22px"
          fontFamily="Poppins-Bold"
          textAlign="center" 
          mb={2}
        >
          Confirm to purchase {item?.name}?
        </Typography>

        {item?.description !== '' && (
          <Typography 
            variant="body1" 
            fontFamily="sora, sans-serif"
            textAlign="center"
            fontSize="16px"
            fontWeight="bolder"
            mb={3}
            sx={{ opacity: 0.8, backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '10px 0px', borderRadius: '10px' }}
          >
            {item?.description}
          </Typography>
        )}

        {/* <Box 
          display="flex" 
          alignItems="center" 
          gap={1} 
          mb={3}
        >
          {item?.currency === 'XTR' ? (
            <Typography 
              variant="h6" 
              fontFamily="sora, sans-serif"
              fontWeight="bold"
            >
              {item?.price} Stars
            </Typography>
          ) : (
            <>
              <img 
                src={item?.currency === 'diamonds' ? 
                  "https://img.icons8.com/color/48/diamond.png" :
                  "https://img.icons8.com/fluency/50/cheap-2.png"
                }
                alt={item?.currency}
                style={{ width: '24px', height: '24px' }}
              />
              <Typography 
                variant="h6" 
                fontFamily="sora, sans-serif"
                fontWeight="bold"
              >
                {formatNumber(item?.price || 0)}
              </Typography>
            </>
          )}
        </Box> */}

        <StyledButton 
          onClick={handleAction}
          disabled={loading}
        >
          {loading ? 'Processing...' : 
           item?.adRequired ? 'Watch Ad to Claim' :
          //  show stars logo
           item?.currency === 'XTR' ? (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography 
                variant="h6" 
                fontFamily="sora, sans-serif"
                fontWeight="bold"
              >
               Pay {item?.price}
              </Typography>
              <img 
                src="https://i.ibb.co/kpnhkML/istockphoto-2150568832-612x612-removebg-preview-min.png" 
                alt="XTR"
                style={{ width: '24px', height: '24px', position: 'relative', left: '-5px', verticalAlign: 'middle', marginBottom: '0px' }}
              />
            </Box>
           ) : 
           'Confirm Purchase'}
        </StyledButton>
      </Box>
    </PopupContainer>
  );
}

ShopPurchasePopup.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    adRequired: PropTypes.bool
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userProfileInfo: PropTypes.object.isRequired,
  setUserProfileInfo: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

export default ShopPurchasePopup;
import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { PartyPopper } from 'lucide-react';
import ProgressBar from './ProgressBar';
import Confetti from '../Confetti/Confetti';

// Keyframes for animations
const slideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const rotate360 = keyframes`
  from { transform: rotate3d(0, 0, 1, 0deg); }
  to { transform: rotate3d(0, 0, 1, 360deg); }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px) scale(1); }
  50% { transform: translateY(-10px) scale(1.02); }
  100% { transform: translateY(0px) scale(1); }
`;

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 0px #FF6D00, 0 0 0px #FF6D00; }
  30% { text-shadow: 0 0 10px #FF6D00, 0 0 10px #FF6D00; }
  100% { text-shadow: 0 0 0px #FF6D00, 0 0 0px #FF6D00; }
`;

// Styled Components
const AnimatedTitle = styled(Typography)`
  animation: ${slideInLeft} 0.8s ease-out forwards;
`;

const AnimatedDescription = styled(Typography)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.3s;
  opacity: 0;
`;

const AnimatedPoints = styled(Typography)`
  animation: ${fadeIn} 0.8s ease-out forwards, ${glowAnimation} 4s infinite;
  animation-delay: 0.5s;
  opacity: 0;
`;

const AnimatedCircle = styled(Box)(({ rotation = '250deg' }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  right: '15vw',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: 'translate3d(0, 0, 0) rotate(' + rotation + ')',
  backfaceVisibility: 'hidden',
  perspective: 1000,
  willChange: 'transform',
  animation: `${rotate360} 7s linear infinite`,
}));

const AnimatedButton = styled(Button)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
`;

const FinalGuidePage = ({ nextPage, registerTelegramYear }) => {
  const [points, setPoints] = useState(0);

  // Calculate Telegram age
  const currentYear = new Date().getFullYear();
  const telegramAge = currentYear - registerTelegramYear;
  const targetPoints = telegramAge === 0 ? 100 : telegramAge * 100;

  // Animation of points
  const duration = 3000; // 3 seconds
  const steps = 60;
  const stepDuration = duration / steps;
  const increment = targetPoints / steps;

  useEffect(() => {
    let currentStep = 0;
    
    const timer = setInterval(() => {
      if (currentStep < steps) {
        setPoints(prev => Math.min(prev + increment, targetPoints));
        currentStep++;
      } else {
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, []);

  const handleCompletion = () => {
    localStorage.setItem('guidesCompleted', 'true');
    window.location.href = '/';
  };

  return (
    <Box
      sx={{
        width: '100vw', 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#ebe9ff',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ProgressBar step={5} />
      
      <AnimatedTitle
        variant="h5"
        align="center"
        fontFamily="Poppins, sans-serif"
        fontWeight="800"
        sx={{ 
          color: 'black', 
          width: '75vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '14vh', 
          left: '5vw', 
          textAlign: 'left' 
        }}
      >
        Congratulations!
        <PartyPopper size={25} weight="fill" fontWeight='bold' color="#FF6D00" style={{ 
          marginLeft: '8px',
          animation: `${floatAnimation} 2s ease-in-out infinite`
        }} />
      </AnimatedTitle>

      <AnimatedDescription
        variant="body1"
        fontFamily="Sora, sans-serif"
        fontWeight="500"
        sx={{ 
          color: 'black', 
          width: '65vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '20vh', 
          left: '5vw', 
          textAlign: 'left' 
        }}
      >
        {registerTelegramYear && telegramAge === 0 ? 
          'Your Telegram account is new so 100 points for you!' :
          <>
              Your Telegram account age is <br />
              <span style={{ color: '#FF6D00', fontWeight: 'bold' }}>
                  {telegramAge} years
              </span> old!
          </>
      }
      </AnimatedDescription>

      <AnimatedPoints
        variant="h3"
        fontFamily="Sora, sans-serif"
        fontWeight="800"
        sx={{ 
          color: '#FF6D00',
          position: 'absolute',
          top: '30vh',
          left: '5vw',
          p: 1
        }}
      >
        +{Math.round(points)} Points
      </AnimatedPoints>

      {/* Fox character */}
      <Box
        sx={{
          width: '300px',
          height: '400px',
          position: 'absolute',
          bottom: '-10vh',
          right: -3,
          zIndex: 2,
          backgroundImage: "url('https://i.ibb.co/Zzx1hs96/Adorable-Anthropomorphic-Fox-In-Purple-Tuxedo-Hold-no-bg-preview-carve-photos-min.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />

      {/* Background circle */}
      <Box
        sx={{
          width: '375px',
          height: '375px',
          position: 'absolute',
          bottom: '-12vh',
          right: '-12vw',
          opacity: 0.8,
          zIndex: 1,
          backgroundImage: "url('https://i.ibb.co/d2w3cQJ/Pngtree-circle-halftone-abstract-dotted-circles-9171026.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: 'scaleX(-1)',
        }}
      />

      <AnimatedCircle
        sx={{
          maxWidth: "10vh",
          maxHeight: "10vh",
          borderRadius: "50%",
          boxShadow: "rgb(27 255 140 / 84%) -18vh -20vh 26vh 10.5vh, #c032ffcc 12vh 15vh 25vh 9vh, #0013ffdb 15vh -16vh 24vh 8.78vh, rgb(0 221 255 / 85%) 10vh -20vh 20vh 2vh, rgb(0 190 255 / 2%) -16vh 11vh 11vh 8.44vh",
          animation: `${rotate360} 10s linear infinite`,
          zIndex: 1
        }}
      />
        <AnimatedButton
        variant="contained"
        color="primary"
        disabled={points >= 100 ? false : true}
        onClick={handleCompletion}
        sx={{ 
          mx: 'auto', 
          width: '85vw', 
          mb: 2, 
          py: 1, 
          fontSize: '1.15rem', 
          fontFamily: 'Sora, sans-serif', 
          fontWeight: 'bold', 
          borderRadius: '20px', 
          textTransform: 'capitalize', 
          zIndex: 9,
          backgroundColor: '#5529ec',
          backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
          boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), 
                     inset 0 -4px 4px 1px #c5b3ff, 
                     inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 
                     0 1px 4px 1px rgba(56, 41, 236, 0.2), 
                     0 1px 3px 1px #5529ec`,
          '&:hover': {
            transform: 'scale(1.04)',
            backgroundColor: '#4a1eec'
          }
        }}
      >
        Let's start
      </AnimatedButton>
    </Box>
  );
};

export default FinalGuidePage;
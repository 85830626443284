import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { HeartHandshake, Wallet } from 'lucide-react';
import ProgressBar from './ProgressBar';

// Keyframes for animations
const slideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const rotate360 = keyframes`
  from { transform: rotate3d(0, 0, 1, 0deg); }
  to { transform: rotate3d(0, 0, 1, 360deg); }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Styled Components
const AnimatedTitle = styled(Typography)`
  animation: ${slideInLeft} 0.8s ease-out forwards;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AnimatedDescription = styled(Typography)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.3s;
  opacity: 0;
`;

const AnimatedPhone = styled(Box)`
  animation: ${slideInRight} 1.2s ease-out forwards, ${floatAnimation} 3s ease-in-out infinite;
  animation-delay: 0.2s;
  opacity: 0;
`;

const AnimatedCircleBackground = styled(Box)`
  animation: ${scaleIn} 1s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
`;

const AnimatedCircle = styled(Box)(({ rotation = '250deg' }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  right: '15vw',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: 'translate3d(0, 0, 0) rotate(' + rotation + ')',
  backfaceVisibility: 'hidden',
  perspective: 1000,
  willChange: 'transform',
  animation: `${rotate360} 7s linear infinite`,
}));

const AnimatedButton = styled(Button)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
`;

const DailyBudgetPage = ({ nextPage, backgroundSource }) => {
  return (
    <Box
      sx={{
        width: '100vw', 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#ebe9ff',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ProgressBar step={3} />
      
      <AnimatedTitle
        variant="h4"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="800"
        sx={{ 
          color: 'black', 
          borderRadius: '20px', 
          width: '85vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '14vh', 
          left: '5vw', 
          textAlign: 'left',
        }}
      >
        Daily Budget 
        <Wallet size={30} color="#FF6D00" style={{ 
          animation: `${floatAnimation} 2s ease-in-out infinite`,
          marginLeft: '8px'
        }} />
      </AnimatedTitle>

      <AnimatedDescription
        variant="h6"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="500"
        sx={{ 
          color: 'black', 
          borderRadius: '20px', 
          width: '85vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '20vh', 
          left: '5vw', 
          textAlign: 'left',
          '& .highlight': {
            color: '#FF6D00',
            fontWeight: 'bold'
          }
        }}
      >
        Spend your <span className="highlight">daily budget</span> on your friends by <span className="highlight">tipping</span> it.
      </AnimatedDescription>

      <AnimatedPhone
        sx={{
          width: '350px',
          height: '725px',
          position: 'absolute',
          bottom: '-45vh',
          right: '5vw',
          zIndex: 2,
          backgroundImage: "url('https://i.ibb.co/gPCttTV/i-Phone-13-PRO-localhost-1-Copy-min.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'drop-shadow(0px 10px 20px rgba(0,0,0,0.2))',
        }}
      />

      <AnimatedCircleBackground
        sx={{
          width: '375px',
          height: '375px',
          position: 'absolute',
          bottom: '13vh',
          right: '-15vw',
          opacity: 1,
          zIndex: 1,
          backgroundImage: "url('https://i.ibb.co/d2w3cQJ/Pngtree-circle-halftone-abstract-dotted-circles-9171026.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: 'scaleX(-1)',
        }}
      />

      <AnimatedCircle
        sx={{
          maxWidth: "10vh",
          maxHeight: "10vh",
          borderRadius: "50%",
          boxShadow: "rgb(27 255 140 / 84%) -18vh -20vh 26vh 10.5vh, #c032ffcc 12vh 15vh 25vh 9vh, #0013ffdb 15vh -16vh 24vh 8.78vh, rgb(0 221 255 / 85%) 10vh -20vh 20vh 2vh, rgb(0 190 255 / 2%) -16vh 11vh 11vh 8.44vh",
          animation: `${rotate360} 10s linear infinite`,
          zIndex: 1
        }}
      />

      <AnimatedButton
        variant="contained"
        color="primary"
        onClick={nextPage}
        sx={{ 
          mx: 'auto', 
          width: '85vw', 
          mb: 2, 
          py: 1, 
          fontSize: '1.15rem', 
          fontFamily: 'Sora, sans-serif', 
          fontWeight: 'bold', 
          borderRadius: '20px', 
          textTransform: 'capitalize', 
          zIndex: 9,
          backgroundColor: '#5529ec',
          backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
          boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), 
                     inset 0 -4px 4px 1px #c5b3ff, 
                     inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 
                     0 1px 4px 1px rgba(56, 41, 236, 0.2), 
                     0 1px 3px 1px #5529ec`,
          '&:hover': {
            transform: 'scale(1.04)',
            backgroundColor: '#4a1eec'
          }
        }}
      >
        Got it
      </AnimatedButton>
    </Box>
  );
};

export default DailyBudgetPage;
import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { PawPrint } from 'lucide-react';
import ProgressBar from './ProgressBar';

// Keyframes for animations
const slideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInRight = keyframes`
  0% {
    transform: translateX(100%) scaleX(-1);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleX(-1);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const rotate360 = keyframes`
  from { transform: rotate3d(0, 0, 1, 0deg); }
  to { transform: rotate3d(0, 0, 1, 360deg); }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

// Styled Components
const AnimatedTitle = styled(Typography)`
  animation: ${slideInLeft} 0.8s ease-out forwards;
`;

const AnimatedDescription = styled(Typography)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.3s;
  opacity: 0;
`;

const AnimatedFoxImage = styled(Box)`
  animation: ${slideInRight} 1s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const AnimatedCircleBackground = styled(Box)`
  animation: ${scaleIn} 1s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
`;

const AnimatedCircle = styled(Box)(({ rotation = '250deg' }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  right: '15vw',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: 'translate3d(0, 0, 0) rotate(' + rotation + ')',
  backfaceVisibility: 'hidden',
  perspective: 1000,
  willChange: 'transform',
  animation: `${rotate360} 7s linear infinite`,
}));

const AnimatedButton = styled(Button)`
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
`;

const WelcomePage = ({ nextPage, backgroundSource }) => {
  return (
    <Box
      sx={{
        width: '100vw', 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#ebe9ff',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden', // Important for animations
      }}
    >
      <ProgressBar step={1} />
      
      <AnimatedTitle
        variant="h3"
        align="center"
        fontFamily="Poppins, sans-serif"
        fontWeight="800"
        sx={{ 
          color: 'black', 
          borderRadius: '20px', 
          width: '65vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '14vh', 
          left: '5vw', 
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        Welcome <br />to FOXI 
        <PawPrint size={40} color="#FF6D00" style={{ marginLeft: '8px' }} />
      </AnimatedTitle>

      <AnimatedDescription
        variant="body1"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="500"
        sx={{ 
          color: 'black', 
          borderRadius: '20px', 
          width: '65vw', 
          mx: 'auto', 
          p: 1, 
          position: 'absolute', 
          top: '32vh', 
          left: '5vw', 
          textAlign: 'left',
          '& span': {
            display: 'block',
            marginBottom: '8px'
          }
        }}
      >
        <span>• Foxi is a not Tap-Tap game, It's Tip to Earn.</span>
        <span>• Make your own tip box</span>
      </AnimatedDescription>

      <AnimatedFoxImage
        sx={{
          width: '300px',
          height: '300px',
          position: 'absolute',
          bottom: 0,
          right: -3,
          zIndex: 2,
          backgroundImage: "url('https://i.ibb.co/mc3Lc5k/Playful-Cartoon-Fox-Surrounded-By-Colorful-Autumn-Leaves-removebg-preview-min-min.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <AnimatedCircleBackground
        sx={{
          width: '375px',
          height: '375px',
          position: 'absolute',
          bottom: '-12vh',
          right: '-12vw',
          zIndex: 1,
          backgroundImage: "url('https://i.ibb.co/d2w3cQJ/Pngtree-circle-halftone-abstract-dotted-circles-9171026.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: 'scaleX(-1)',
        }}
      />

      <AnimatedCircle
        sx={{
          maxWidth: "10vh",
          maxHeight: "10vh",
          borderRadius: "50%",
          boxShadow: "rgb(27 255 140 / 84%) -18vh -20vh 26vh 10.5vh, #c032ffcc 12vh 15vh 25vh 9vh, #0013ffdb 15vh -16vh 24vh 8.78vh, rgb(0 221 255 / 85%) 10vh -20vh 20vh 2vh, rgb(0 190 255 / 2%) -16vh 11vh 11vh 8.44vh",
          animation: `${rotate360} 10s linear infinite`,
          zIndex: 1
        }}
      />

      <AnimatedButton
        variant="contained"
        color="primary"
        onClick={nextPage}
        sx={{ 
          mx: 'auto', 
          width: '85vw', 
          mb: 2, 
          py: 1, 
          fontSize: '1.15rem', 
          fontFamily: 'Sora, sans-serif', 
          fontWeight: 'bold', 
          borderRadius: '20px', 
          textTransform: 'capitalize', 
          zIndex: 9,
          backgroundColor: '#5529ec',
          backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
          boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), 
                     inset 0 -4px 4px 1px #c5b3ff, 
                     inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 
                     0 1px 4px 1px rgba(56, 41, 236, 0.2), 
                     0 1px 3px 1px #5529ec`,
          '&:hover': {
            transform: 'scale(1.04)',
            backgroundColor: '#4a1eec'
          }
        }}
      >
        Start
      </AnimatedButton>
    </Box>
  );
};

export default WelcomePage;
// src/App.js
import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import socketClient from './socket';

// Components
import Snowfall from 'react-snowfall'
import WonkaHome from './components/Home';
import TaskPage from './components/Task';
import ShopPage from './components/Shop';
import RewardsPage from './components/Rewards';
import ReferralPage from './components/Referral';
import MaintenancePage from './components/Maintenance';
import LoadingScreen from './components/Home/LoadingScreen';
import DesktopPage from './components/DesktopPage';
import SocketMonitor from './components/Admin/SocketMonitor';
import CustomSnackbar from './components/Snakebar/Snakebar';
import Guide from './components/Guide';

// Utils
import useImagePreloader from './utils/ImagePreloader';
import { userApi } from './services/api';

// Audio imports
import playgroundMusic from './assets/music/lofi-chill.mp3';
import selectSound from './assets/music/select-2.wav';
import successSound from './assets/music/success.wav';
import errorSound from './assets/music/error.wav';
import infoSound from './assets/music/info.wav';
import spinWheelSound from './assets/music/spin-wheel.mp3';

const telApp = window.Telegram.WebApp;

const theme = createTheme({
 breakpoints: {
   values: {
     mobile: 0,
     tablet: 640,
     laptop: 1024,
     desktop: 1280,
   },
 },
});

const ENDPOINT = process.env.REACT_APP_API_URL;

function App() {
 const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(false);
 const [userData, setUserData] = useState(null);
 const [userProfileInfo, setUserProfileInfo] = useState(null);
 const [profileUrl, setProfileUrl] = useState(null);
 const [userPlatform, setUserPlatform] = useState(null);
 const [openConfetti, setOpenConfetti] = useState(false);
 const { imagesLoaded, progress } = useImagePreloader();
 const [snackbar, setSnackbar] = useState({
   open: false,
   message: '',
   type: 'info',
   doubleDuration: false
 });
 
 // Music state
 const [audioPlayer] = useState(new Audio(playgroundMusic));  
 const [isMusicOn, setIsPlaying] = useState(() => {
   const shouldPlay = localStorage.getItem('musicPlaying');
   return shouldPlay === null || shouldPlay === 'true';
 });

 // Sound Effects state
 const [pageTransitionPlayer] = useState(new Audio(selectSound));
 const [successPlayer] = useState(new Audio(successSound));
 const [errorPlayer] = useState(new Audio(errorSound));
 const [infoPlayer] = useState(new Audio(infoSound));
 const [spinPlayer] = useState(new Audio(spinWheelSound));
 const [isSoundEffectsOn] = useState(() => {
   const stored = localStorage.getItem('soundEffects');
   return stored === null ? true : stored === 'true';
 });

 const location = useLocation();

 const playPageTransition = useCallback(() => {
   if (isSoundEffectsOn) {
     pageTransitionPlayer.currentTime = 0;
     pageTransitionPlayer.play().catch(error => {
       console.error("Error playing transition sound:", error);
     });
   }
 }, [isSoundEffectsOn, pageTransitionPlayer]);

 const playSnackbarSound = useCallback((type) => {
   if (!isSoundEffectsOn) return;

   const player = {
     success: successPlayer,
     error: errorPlayer,
     info: infoPlayer
   }[type];

   if (player) {
     player.currentTime = 0;
     player.play().catch(error => {
       console.error("Error playing snackbar sound:", error);
     });
   }
 }, [isSoundEffectsOn, successPlayer, errorPlayer, infoPlayer]);

 // Initialize sound settings
 useEffect(() => {
   const players = [pageTransitionPlayer, successPlayer, errorPlayer, infoPlayer, spinPlayer];
   players.forEach(player => {
     player.volume = 0.3;
   });
 }, [pageTransitionPlayer, successPlayer, errorPlayer, infoPlayer, spinPlayer]);

 // Play sound on route changes
 useEffect(() => {
   playPageTransition();
 }, [location, playPageTransition]);

 // Initialize Telegram WebApp and Socket
 const init = async () => {
   telApp.ready();    
   telApp.expand();

   setUserPlatform(telApp.platform);
   const startAppId = Number(telApp.initDataUnsafe.start_param);
   const initData = telApp.initData;
   
   if (initData) {
       try {
           const response = await userApi.authenticateUser(initData, startAppId);
           if (response.data.authenticated) {
               localStorage.setItem('jwtToken', response.data.token);
               setUserData(response.data.user);
               setIsTelegramMiniApp(true);
               
               socketClient.initialize(ENDPOINT, response.data.token);
               await getProfileInfo(response.data.user.id);
           } else {
               throw new Error('Authentication failed');
           }
       } catch (error) {
           console.error('Authentication error:', error);
           telApp.showAlert('Authentication failed. Please try again.');
           telApp.close();
       }
   } else {
       setIsTelegramMiniApp(false);

       if (process.env.REACT_APP_NODE_ENV === 'local') {
         const devUser = {
             "id": 1887509957,
             "first_name": "Its Justin",
             "last_name": "",
             "username": "P2P_JS",
             "language_code": "en",
             "is_premium": true,
             "allows_write_to_pm": true
         };
         setUserData(devUser);

         const devToken = 'dev_' + Date.now();
         localStorage.setItem('jwtToken', devToken);
         
         socketClient.initialize(ENDPOINT, devToken);
         await getProfileInfo(devUser.id);
      } else {
          window.location.href = 'https://t.me/FoxiTips_bot';
      }
   }
 };

 const getProfileInfo = async (userId) => {
   try {
     const response = await userApi.getUserData(userId);
     telApp.setHeaderColor('#010510');
     setTimeout(() => {
       setUserProfileInfo(response.data);
     }, 1000);
     
     setProfileUrl(`https://app.foxi.tips/api/public/users/avatars/${userId}.jpg`);
   } catch (error) {
     console.error('Error fetching user data:', error);
     telApp?.showAlert('Failed to get user information data!\nFor support, Please check bot profile bio!');
     telApp.close();
   }
 };

 // Setup socket subscription for user updates
 useEffect(() => {
   if (userData?.id) {
     const cleanup = socketClient.subscribeToUserUpdates(async (updatedUserData) => {
       setUserProfileInfo(prevState => ({
         ...prevState,
         ...updatedUserData
       }));

       if (updatedUserData?.league?.allowance === 0) {
           const newData = await userApi.getUserData(userData.id);
           setUserProfileInfo(newData.data);
       } 
     });

     return () => {
       cleanup();
       socketClient.disconnect();
     };
   }
 }, [userData?.id]);

 // Initialize setup
 useEffect(() => {
   init();
 }, []);

 // Initialize audio player
 useEffect(() => {
   audioPlayer.volume = 0.5;
   audioPlayer.loop = true; 
   
   if (isMusicOn) {
     const playPromise = audioPlayer.play();
     
     if (playPromise !== undefined) {
       playPromise.catch(() => {
         setIsPlaying(false);
       });
     }
   }
 
   return () => {
     audioPlayer.pause();
     audioPlayer.currentTime = 0;
   };
 }, [audioPlayer]);

 const handleMusicToggle = () => {
   try {
     if (isMusicOn) {
       audioPlayer.pause();
       localStorage.setItem('musicPlaying', 'false');
       setIsPlaying(false);
     } else {
       const playPromise = audioPlayer.play();
       
       if (playPromise !== undefined) {
         playPromise
           .then(() => {
             localStorage.setItem('musicPlaying', 'true');
             setIsPlaying(true);
           })
           .catch(error => {
             console.error("Playback failed:", error);
             setIsPlaying(false);
           });
       }
     }
   } catch (error) {
     console.error("Music toggle failed:", error);
     setIsPlaying(false);
   }
 };

 const showSnackbar = (message, type, doubleDuration = false) => {
   setSnackbar({ open: true, message, type, doubleDuration });
   playSnackbarSound(type);
 };

 const closeSnackbar = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setSnackbar({ ...snackbar, open: false });
 };

 if (userPlatform === 'tdesktop' && userData?.id !== 1887509957) {
   telApp.setHeaderColor('#1D0A37');
   return <DesktopPage />;
 }

 if (!userProfileInfo || !imagesLoaded) {
   telApp.setHeaderColor('#000115');
   return <LoadingScreen progressPercentage={progress} />;
 }

 const hasSeenGuides = localStorage.getItem('guidesCompleted');
 if (!hasSeenGuides && window.location.pathname !== '/guide') {
   window.location.href = '/guide';
   return null;
 }

 return (
   <ThemeProvider theme={theme}>
     <Snowfall snowflakeCount={5} />

     <CustomSnackbar
       open={snackbar.open}
       message={snackbar.message}
       type={snackbar.type}
       duration={snackbar.doubleDuration ? 4000 : 2000}
       onClose={closeSnackbar}
     />

     <Routes>
       <Route 
         path="/" 
         element={
           <WonkaHome 
             theme={theme}
             userData={userData}
             userProfileInfo={userProfileInfo} 
             setUserProfileInfo={setUserProfileInfo}
             profileUrl={profileUrl}
             userPlatform={userPlatform} 
             openConfetti={openConfetti}
             isMusicOn={isMusicOn}
             onMusicToggle={handleMusicToggle}
             setOpenConfetti={setOpenConfetti}
             showSnackbar={showSnackbar}
             telApp={telApp}
             ENDPOINT={ENDPOINT}
           />
         } 
       />
       
       <Route 
         path="/task" 
         element={
           <TaskPage 
             theme={theme}
             userProfileInfo={userProfileInfo} 
             openConfetti={openConfetti}
             setOpenConfetti={setOpenConfetti}
             showSnackbar={showSnackbar}
             telApp={telApp}
             ENDPOINT={ENDPOINT}
           />
         } 
       />

       <Route
         path="/shop"
         element={
           <ShopPage
             theme={theme}
             userProfileInfo={userProfileInfo}
             openConfetti={openConfetti}
             setOpenConfetti={setOpenConfetti}
             showSnackbar={showSnackbar}
             telApp={telApp}
             ENDPOINT={ENDPOINT}
           />
         }
       />

       <Route 
         path="/rewards" 
         element={
           <RewardsPage
             theme={theme}
             userProfileInfo={userProfileInfo} 
             setUserProfileInfo={setUserProfileInfo}
             openConfetti={openConfetti}
             setOpenConfetti={setOpenConfetti}
             showSnackbar={showSnackbar}
             telApp={telApp}
             ENDPOINT={ENDPOINT}
             isSoundEffectsOn={isSoundEffectsOn}
             spinPlayer={spinPlayer}
           />
         } 
       />

       <Route 
         path="/referrals" 
         element={
           <ReferralPage
             theme={theme}
             userData={userData}
             userProfileInfo={userProfileInfo}
             telApp={telApp}
             ENDPOINT={ENDPOINT}
           />
         } 
       />

       <Route 
         path="/guide" 
         element={
           <Guide 
             registerTelegramYear={userProfileInfo?.estimatedRegisterYear || 2025}
           />
         } 
       />

       <Route path="/maintenance" element={<MaintenancePage />} />
       <Route path="/admin/socket-monitor" element={<SocketMonitor />} />
     </Routes>
   </ThemeProvider>
 );
}

export default App;
import React, { memo } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import Loading from '../Loading/Loading';
import PropTypes from 'prop-types';

const ItemCard = styled(Box)(({ theme, isPremiumItem }) => ({
  backgroundColor: 'rgb(17 11 38 / 70%)',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #005152de, transparent), radial-gradient(75% 35% at 50% 80%, #7f710063, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgb(0 0 0 / 20%)`,
  borderRadius: '20px',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'all 0.2s ease',
  height: '140px',
  width: '80%',
  marginLeft: 'auto',  // Add this for centering
  marginRight: 'auto', // Add this for centering 
  zIndex: 1, // Base z-index
  '&:hover': {
    transform: 'scale(1.02)',
    background: 'rgba(0, 0, 0, 0.7)'
  }
 })
);

const Banner = styled(Box)({
  width: '92vw',
  height: '17vh',
  margin: '0 auto 20px auto',
  borderRadius: '15px',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)'
  },
  zIndex: 99
});

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: '0.9rem',
  textTransform: 'none',
  height: '35px',
  width: '100%',
  padding: '6px 14px',
  marginTop: '5px',
  backgroundColor: disabled ? 'rgba(85, 41, 236, 0.5)' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
              inset 0 -4px 4px 1px #c5b3ff,
              inset 0 0 0px 8px rgba(255, 255, 255, 0.2)`,
  color: 'white',
  fontSize: '13px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  transition: 'all 0.2s ease-in-out',
  opacity: disabled ? 0.7 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    backgroundColor: disabled ? 'rgba(85, 41, 236, 0.5)' : '#4a20e3',
    transform: disabled ? 'none' : 'scale(1.02)'
  }
}));

const ShopGrid = memo(({ items, onPurchase, loading, confirmBanner = false }) => {
  if (loading) {
    return <Loading loading={true} />;
  }

  // Find banner item
  const bannerItem = items.find(item => item.isBanner && item.bannerImage);

  return (
    <Grid container spacing={2}>
      {/* Banner */}
      {bannerItem && confirmBanner ? (
        <Grid item xs={12} sx={{ zIndex: 99 }}>
          <Banner 
            onClick={() => onPurchase(bannerItem)}
            component="img"
            src={bannerItem.bannerImage}
            alt={bannerItem.name}
          />
        </Grid>
      ) : (
      <Grid 
        container 
        display="flex" 
        flexDirection="row" 
        flexWrap="nowrap" 
        gap={2} 
        xs={12} 
        sx={{ 
          justifyContent: 'center', 
          padding: '0px' 
        }}
      >
        {/* Map through non-banner items */}
        {items
          .map((item) => (
            <Grid 
              item 
              xs={4} 
              key={item.id} 
              mt={2} 
              sx={{ 
                marginLeft: '15px', 
                zIndex: 99 
              }}
            >
              {/* Item Card Container */}
              <ItemCard isPremiumItem={item.category === 'premium'}>
                {/* Item Icon */}
                <Box
                  component="img"
                  src={item.icon}
                  alt={item.name}
                  sx={{
                    width: '45px',
                    height: '45px',
                    objectFit: 'contain',
                    justifyContent: 'center'
                  }}
                />
                
                {/* Item Name */}
                <Typography 
                  variant="body1" 
                  fontFamily="sora, sans-serif"
                  textAlign="center"
                  fontSize="13px"
                  mb={0.5}
                >
                  {(item.name).toLowerCase().replace('diamonds', 'Gem')}
                </Typography>

                {/* Item Description */}
                {/* {item.description !== '' && item.category !== 'premium' && (
                    // Short description - show as text
                    <Typography 
                      variant="body2" 
                      fontFamily="sora, sans-serif"
                      textAlign="center"
                      fontSize="11px"
                      mb={1}
                      sx={{ opacity: 0.7 }}
                    >
                      {item.description}
                    </Typography>
                  )
                } */}

                {/* Premium Features Display */}
                {/* {item.category === 'premium' && (
                  <Box sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    mb: 1
                  }}>
                    {item.rewards.farmMultiplier && (
                      <Typography 
                        variant="caption" 
                        fontFamily="sora, sans-serif"
                        sx={{
                          fontSize: '10px',
                          opacity: 0.9,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }}
                      >
                        * {item.rewards.farmMultiplier}x Farm Boost
                      </Typography>
                    )}
                    {item.rewards.tipLimitPercentage && (
                      <Typography 
                        variant="caption" 
                        fontFamily="sora, sans-serif"
                        sx={{
                          fontSize: '10px',
                          opacity: 0.9,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }}
                      >
                        * {item.rewards.tipLimitPercentage}% Tip Limit
                      </Typography>
                    )}

                    {item.rewards.diamonds && (
                      <Typography 
                        variant="caption" 
                        fontFamily="sora, sans-serif"
                        sx={{
                          fontSize: '10px',
                          opacity: 0.9,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }}
                      >
                        * {item.rewards.diamonds} Gems
                      </Typography>
                    )}

                     {item.rewards.points && (
                      <Typography 
                        variant="caption" 
                        fontFamily="sora, sans-serif"
                        sx={{
                          fontSize: '10px',
                          opacity: 0.9,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }}
                      >
                        * {item.rewards.points} Points
                      </Typography>
                    )}
                  </Box>
                )} */}

                {/* Price Display */}
                <Box 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center"
                  gap={0.5}
                  mb={1}
                >
                  {item.currency === 'XTR' ? (
                    <Typography 
                      fontFamily="sora, sans-serif" 
                      fontSize="14px" 
                      fontWeight="bold"
                    >
                      {item.price} 
                      <img src="https://i.ibb.co/kpnhkML/istockphoto-2150568832-612x612-removebg-preview-min.png" alt="XTR" style={{ width: '16px', height: '16px', marginBottom: '0px', verticalAlign: 'text-top' }}/>
                    </Typography>
                  ) : (
                    <>
                      <img 
                        src={item.currency === 'diamonds' ? 
                          "https://img.icons8.com/color/48/diamond.png" :
                          "https://img.icons8.com/fluency/50/cheap-2.png"
                        }
                        alt={item.currency}
                        style={{ 
                          width: '16px', 
                          height: '16px', 
                          marginBottom: '0px' 
                        }}
                      />
                      <Typography 
                        fontFamily="sora, sans-serif"
                        fontSize="14px" 
                        fontWeight="bold"
                      >
                        {item.price}
                      </Typography>
                    </>
                  )}
                </Box>

                {/* Purchase Button */}
                <StyledButton 
                  onClick={() => onPurchase(item)}
                  disabled={item.dailyLimit?.current >= item.dailyLimit?.max}
                >
                  {item.dailyLimit?.current >= item.dailyLimit?.max ? 
                    'Limit Reached' : 'Purchase'
                  }
                </StyledButton>
              </ItemCard>
            </Grid>
          ))}
      </Grid>
      )}
    </Grid>
  );
});

ShopGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    dailyLimit: PropTypes.shape({
      current: PropTypes.number,
      max: PropTypes.number
    }),
    isBanner: PropTypes.bool,
    bannerImage: PropTypes.string
  })).isRequired,
  onPurchase: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  confirmBanner: PropTypes.bool
};

export default ShopGrid;
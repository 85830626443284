import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Container
} from '@mui/material';
import { styled } from '@mui/system';
import HistoryIcon from '@mui/icons-material/History';
import { shopApi } from '../services/api';
import ShopGrid from './Shop/ShopGrid';
import ShopPurchasePopup from './Shop/ShopPurchasePopup';
import BottomTabs from './Tabs/Tabs';
import GradientBoxShadow from './GradientBoxShadow/GradientBoxShadow';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useTonAddress } from '@tonconnect/ui-react';
import PropTypes from 'prop-types';

const SHOP_CATEGORIES = {
  ALL: 'All',
  SPECIAL: 'Special'
};

// Main container with fixed height and hidden overflow
const ShopContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  maxHeight: '100vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  color: 'white',
  position: 'relative',
  overflowY: 'hidden',
  display: 'flex',
  flexDirection: 'column'
}));

// Header container with gradient background
const HeaderContainer = styled(Box)({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  background: 'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
  padding: '16px 0',
  backdropFilter: 'blur(5px)'
});

// Scrollable main content area
const ScrollableContent = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingBottom: '80px',
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '3px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#5529ec',
    borderRadius: '3px',
    '&:hover': {
      background: '#6d43ff'
    }
  }
});

// Horizontal scrollable container for package rows
const PackageRow = styled(Box)(({ theme, isPremium }) => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  paddingBottom: '8px',
  marginBottom: '12px',
  height: '165px',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '3px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#5529ec',
    borderRadius: '3px',
    '&:hover': {
      background: '#6d43ff'
    }
  }
}));

// Currency display box
const StatBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '8px 12px',
  height: '32px',
  borderRadius: '12px',
  background: 'rgba(0, 0, 0, 0.4)',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.1)'
});

// Category toggle buttons styling
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  justifyContent: 'center',
  '& .MuiToggleButtonGroup-grouped': {
    border: '2px solid rgba(255, 255, 255, 0.5)',
    width: '35vw',
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 'bold',
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    '&.Mui-selected': {
      backgroundColor: '#5529ec',
      color: 'white',
      boxShadow: `
        inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
        inset 0 -4px 4px 1px #c5b3ff,
        inset 0 0 0px 8px rgba(255, 255, 255, 0.2),
        0 1px 4px 1px rgba(56, 41, 236, 0.2),
        0 1px 3ax 1px #5529ec
      `,
      filter: 'hue-rotate(300deg)',
      '&:hover': {
        backgroundColor: '#6339ec'
      }
    }
  }
}));

const StyledToggleButton = styled(ToggleButton)(({ active, side }) => ({
  textTransform: 'none',
  height: '45px',
  fontSize: '15px',
  position: 'relative',
  borderRadius: side === "left" ? '10px 0 0 10px' : '0 10px 10px 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: active ? 'linear-gradient(to bottom, #e9f0ff, transparent)' : 'none',
    opacity: 0.75
  }
}));

// Section title styling
const SectionTitle = styled(Typography)({
  fontFamily: 'sora',
  fontWeight: 'bold',
  fontSize: '16px',
  marginBottom: '8px',
  marginTop: '12px',
  paddingLeft: '16px',
  position: 'relative',
  zIndex: 9
});

// Item container for package grid
const ItemContainer = styled(Box)(({ isPremiumItem }) => ({
  maxWidth: '125px',
  height: isPremiumItem ? '200px' : '180px',
  display: 'inline-block',
  verticalAlign: 'top',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
 })
);

function Shop({ userProfileInfo, showSnackbar, setUserProfileInfo, telApp }) {
  const [shopItems, setShopItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(SHOP_CATEGORIES.ALL);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const userAddress = useTonAddress();

  const fetchShopItems = useCallback(async () => {
    try {
      const response = await shopApi.getShopItems(userProfileInfo.userId);
      if (response.data.success) {
        setShopItems((response.data.items).sort((a, b) => a.sortOrder - b.sortOrder));
      }
    } catch (error) {
      showSnackbar('Failed to load shop items', 'error');
    } finally {
      setLoading(false);
    }
  }, [userProfileInfo.userId, showSnackbar]);

  useEffect(() => {
    fetchShopItems();
    telApp?.setHeaderColor('#010510');
  }, [fetchShopItems, telApp]);

  // Filter items based on category and special status
  const filteredItems = useMemo(() => {
    if (selectedCategory === SHOP_CATEGORIES.SPECIAL) {
      return shopItems.filter(item => item.isSpecial);
    }
    return shopItems;
  }, [shopItems, selectedCategory]);

  // Get categorized items
  const categorizedItems = useMemo(() => {
    const items = filteredItems;
    return {
      bannerItem: items.find(item => item.isBanner),
      diamondItems: items.filter(item => item.category === 'diamond'),
      premiumItems: items.filter(item => item.category === 'premium')
    };
  }, [filteredItems]);

  const handlePurchase = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  const handleCategoryChange = useCallback((_, newValue) => {
    if (newValue !== null) {
      setSelectedCategory(newValue);
    }
  }, []);

  // if user id is not 1887509957 then show alert shop coming soon
  // if (userProfileInfo.userId !== '672826039') {
  //   return (
  //     <ShopContainer>
  //       <Typography variant="h4" component="h2" fontFamily="Poppins-Bold" fontWeight="bolder" textAlign="center" sx={{ mt: '30vh', width: '100%' }}>
  //         Shop coming soon!
  //       </Typography>
  //       <DotLottieReact
  //         src="https://lottie.host/0ac2186d-2be8-4a64-8efc-c3b035d0b959/8BIZGX2YLW.lottie"
  //         loop
  //         autoplay
  //         style={{ width: '150px', height: '150px', marginLeft: 'auto', marginRight: 'auto', mt: '35px' }}
  //       />
  //       <BottomTabs activeTab={2} />
  //     </ShopContainer>
  //   );
  // }

  return (
    <ShopContainer>
      <HeaderContainer>
        <Box sx={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          px: 2
        }}>
          <Typography 
            variant="h4" 
            component="h1" 
            fontFamily="Poppins-Bold"
            fontSize="28px"
          >
            SHOP
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StatBox>
              <img 
                src="https://img.icons8.com/color/48/diamond.png" 
                alt="Diamonds"
                style={{ width: '18px', height: '18px' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                {userProfileInfo.treasury?.diamonds || 0}
              </Typography>
            </StatBox>
            <StatBox>
              <img 
                src="https://img.icons8.com/fluency/50/cheap-2.png"
                alt="Points"
                style={{ width: '18px', height: '18px' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                {userProfileInfo.treasury?.points || 0}
              </Typography>
            </StatBox>
          </Box>
        </Box>

        {/* <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '20px', mt: 2 }}>
          <StyledToggleButtonGroup
            value={selectedCategory}
            exclusive
            onChange={handleCategoryChange}
            aria-label="shop category"
          >
            <StyledToggleButton
              value={SHOP_CATEGORIES.ALL}
              aria-label="all items"
              active={selectedCategory === SHOP_CATEGORIES.ALL}
              side="left"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img 
                  src="https://img.icons8.com/fluency/100/four-squares.png"
                  alt="all"
                  style={{ 
                    width: '20px', 
                    height: '20px',
                    filter: selectedCategory === SHOP_CATEGORIES.ALL ? 'brightness(1.2)' : 'brightness(0.9)'
                  }} 
                />
                All
              </Box>
            </StyledToggleButton>
            <StyledToggleButton
              value={SHOP_CATEGORIES.SPECIAL}
              aria-label="special items"
              active={selectedCategory === SHOP_CATEGORIES.SPECIAL}
              side="right"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img 
                  src="https://img.icons8.com/3d-fluency/94/star.png"
                  alt="special"
                  style={{ 
                    width: '20px', 
                    height: '20px',
                    filter: selectedCategory === SHOP_CATEGORIES.SPECIAL ? 'brightness(1.2)' : 'brightness(0.9)'
                  }} 
                />
                Special
              </Box>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box> */}
      </HeaderContainer>

      <ScrollableContent>
        {categorizedItems.bannerItem && (
          <Box sx={{ px: 2, mb: 1 }}>
            <SectionTitle sx={{ pl: 0 }}>Special Offer 🔥</SectionTitle>
            <ShopGrid 
              confirmBanner={true}
              items={[categorizedItems.bannerItem]}
              onPurchase={handlePurchase}
              isBanner={true}
            />
          </Box>
        )}

        {categorizedItems.premiumItems.length > 0 && (
          <Box>
            <SectionTitle sx={{ mt: '0px' }}>Premium Items ✨</SectionTitle>
            <PackageRow isPremium={true}>
              <Box sx={{ display: 'inline-flex', px: 2, gap: 2, minWidth: '0%', maxWidth: '100%' }}>
                {categorizedItems.premiumItems.map(item => (
                  <ItemContainer key={item.id} isPremiumItem={true}>
                    <ShopGrid 
                      items={[item]}
                      onPurchase={handlePurchase}
                      singleItem
                    />
                  </ItemContainer>
                ))}
              </Box>
            </PackageRow>
          </Box>
        )}

        {categorizedItems.diamondItems.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <SectionTitle>Gem Bags 💎</SectionTitle>
            <PackageRow>
              <Box sx={{ display: 'inline-flex', px: 2, gap: 2 }}>
                {categorizedItems.diamondItems.map(item => (
                  <ItemContainer key={item.id}>
                    <ShopGrid 
                      items={[item]}
                      onPurchase={handlePurchase}
                      singleItem
                    />
                  </ItemContainer>
                ))}
              </Box>
            </PackageRow>
          </Box>
        )}
      </ScrollableContent>

      <ShopPurchasePopup
        item={selectedItem}
        open={Boolean(selectedItem)}
        onClose={() => setSelectedItem(null)}
        userProfileInfo={userProfileInfo}
        userAddress={userAddress}  // Add this line
        setUserProfileInfo={setUserProfileInfo}
        showSnackbar={showSnackbar}
      />

      <GradientBoxShadow />
      
      <BottomTabs activeTab={2} />
    </ShopContainer>
  );
}

Shop.propTypes = {
  userProfileInfo: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    treasury: PropTypes.shape({
      diamonds: PropTypes.number,
      points: PropTypes.number
    })
  }).isRequired,
  showSnackbar: PropTypes.func.isRequired,
  setUserProfileInfo: PropTypes.func.isRequired,
  telApp: PropTypes.object
};

export default Shop;
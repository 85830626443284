// SpinWheelGame.jsx
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { styled, keyframes } from '@mui/system';
import SpinButton from "./SpinWheelButton";
import { userApi } from '../../../services/api';
import { formatNumber } from '../../../utils/Formatters';
import "./index.css";

// Styled components for better organization
const WheelContainer = styled('div')({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const MultiplierButton = styled(Button)(({ disabled }) => ({
  width: '50px',
  height: '58px',
  borderRadius: '50%',
  position: 'absolute',
  left: '85%',
  bottom: '5.5vh',
  color: 'white',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  background: 'linear-gradient(to right, #7c3aed, #5b21b6)',
  boxShadow: '0 0 0 5px rgb(255 165 0), 0 0 24px 4px rgb(255 165 0), inset 0px 10px 15px #9b6707a8, 0 4px 6px rgb(0 0 0 / 73%)',
  transition: 'all 0.3s ease',
  opacity: disabled ? 0.7 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  zIndex: 2,
  '&:hover': {
    background: 'linear-gradient(to right, #5b21b6, #7c3aed)',
  }
}));

const particleAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  100% { transform: translate(var(--tx), var(--ty)) scale(0); opacity: 0; }
`;

const Particle = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '10px',
  height: '10px',
  background: 'white',
  borderRadius: '50%',
  opacity: 0,
  animation: `${particleAnimation} 2s ease-out infinite`,
  zIndex: -1,
});

const PRIZES = [
  { name: '10 points', type: 'points', amount: 10, weight: 25, index: 0 },
  { name: '25 points', type: 'points', amount: 25, weight: 20, index: 1 },
  { name: '1 diamonds', type: 'diamonds', amount: 1, weight: 15, index: 2 },
  { name: '50 points', type: 'points', amount: 50, weight: 10, index: 3 },
  { name: '75 points', type: 'points', amount: 75, weight: 8, index: 4 },
  { name: '2 diamonds', type: 'diamonds', amount: 2, weight: 6, index: 5 },
  { name: '100 points', type: 'points', amount: 100, weight: 4, index: 6 },
  { name: '150 points', type: 'points', amount: 150, weight: 3, index: 7 },
  { name: '250 points', type: 'points', amount: 250, weight: 2, index: 8 },
  { name: '3 diamonds', type: 'diamonds', amount: 3, weight: 1, index: 9 },
  { name: 'None', type: 'none', amount: 0, weight: 6, index: 10 }
];

const SpinWheelGame = ({ 
  userProfileInfo, 
  isSpinning, 
  setIsSpinning, 
  showSnackbar, 
  onBalanceUpdate,
  isSoundEffectsOn,
  spinPlayer
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [multiplier, setMultiplier] = useState(1);
  const [particles, setParticles] = useState([]);
  const colors = ['#FFC8DD', '#FFAFCC', '#BDE0FE', '#A2D2FF', '#88B8FF', '#CDB4DB'];

  // Define the multiplier sequence to cycle through
  const MULTIPLIER_SEQUENCE = [1, 2, 10, 50, 100];

  // Reset wheel when unmounting or changing pages
  useEffect(() => {
    const wheel = document.querySelector('.wheel');
    if (wheel) {
      wheel.style.transition = 'none';
      wheel.style.transform = 'rotate(0deg)';
    }

    return () => {
      const wheel = document.querySelector('.wheel');
      if (wheel) {
        wheel.style.transition = 'none';
        wheel.style.transform = 'rotate(0deg)';
      }
    };
  }, []);

  // Create particle effects
  useEffect(() => {
    const shapes = ['circle'];
    const newParticles = Array.from({ length: 30 }, (_, i) => ({
      id: i,
      size: Math.random() * 7 + 15,
      tx: (Math.random() - 0.5) * 1200,
      ty: (Math.random() - 0.5) * 1200,
      delay: Math.random(),
      duration: Math.random() * 10 + 5,
      shape: shapes[Math.floor(Math.random() * shapes.length)],
      color: `#fff`,
    }));
    setParticles(newParticles);
  }, []);

  // Function to cycle through multipliers
  const cycleMultiplier = () => {
    if (isSpinning) return;
    
    const currentIndex = MULTIPLIER_SEQUENCE.indexOf(multiplier);
    const nextIndex = (currentIndex + 1) % MULTIPLIER_SEQUENCE.length;
    setMultiplier(MULTIPLIER_SEQUENCE[nextIndex]);
  };

  const selectItem = useCallback(async () => {
    if (isSpinning) {
      showSnackbar('Please wait for the spin to finish.', 'error');
      return;
    }

    if (!userProfileInfo) {
      showSnackbar('Data not loaded. Please wait for a moment or refresh the page.', 'error');
      return;
    }

    const requiredDiamonds = multiplier;
    if (userProfileInfo.diamonds < requiredDiamonds) {
      showSnackbar(`You need ${requiredDiamonds} diamonds to spin with ${multiplier}x multiplier.`, 'error');
      return;
    }

    setSelectedItem(null);
    const wheel = document.querySelector('.wheel');
    wheel.style.transition = 'none';
    wheel.style.transform = 'rotate(0deg)';
    
    void wheel.offsetHeight;

    if (!isSpinning) {
      setIsSpinning(true);
      
      try {
        console.log(`Spinning wheel with ${multiplier}x multiplier...`);
        const response = await userApi.spinWheel(userProfileInfo.userId, { multiplier });
        if (response.data.success) {
          const serverPrize = response.data.data.prize;
          const spinDuration = 4000;
          const totalTurns = 4;
          const itemCount = PRIZES.length;
          const prizeIndex = serverPrize.index;
          const degreesPerItem = 360 / itemCount;
          const totalDegrees = totalTurns * 360 + (360 - (prizeIndex * degreesPerItem));
          
          if (isSoundEffectsOn && spinPlayer) {
            spinPlayer.currentTime = 0;
            spinPlayer.play().catch(error => {
              console.error("Error playing spin sound:", error);
            });
          }

          wheel.style.transition = `transform ${spinDuration}ms cubic-bezier(0.25, 0.1, 0.25, 1)`;
          wheel.style.transform = `rotate(${totalDegrees}deg)`;
          
          setTimeout(() => {
            setSelectedItem(prizeIndex);
            setIsSpinning(false);
            if (serverPrize.type === 'none') {
              showSnackbar('You did not win anything!', 'info');
            } else {
              const multiplierText = multiplier > 1 ? ` (${multiplier}x)` : '';
              showSnackbar(
                `You won ${formatNumber(serverPrize.amount)} ${serverPrize.type}${multiplierText}!`, 
                'success'
              );
            }
            onBalanceUpdate(response.data.data.treasury.current);
          }, spinDuration);
        }
      } catch (error) {
        console.error('Error spinning wheel:', error);
        showSnackbar(error?.response?.data?.error || 'Failed to spin the wheel. Please try again.', 'error');
        setIsSpinning(false);
      }
    }
  }, [isSpinning, userProfileInfo, multiplier, onBalanceUpdate, showSnackbar, isSoundEffectsOn, spinPlayer]);

  const renderWheelItem = useCallback(({ item, index }) => {
    const color = colors[index % colors.length];
    const isPrizeNone = item.name.toLowerCase().includes('none');
    const isPrizeDiamond = item.name.toLowerCase().includes('diamond');
    const isPrizePoints = item.name.toLowerCase().includes('points');
    const coinIconSrc = 'https://img.icons8.com/fluency/50/cheap-2.png';
    const diamondIconSrc = 'https://img.icons8.com/color/48/diamond.png';

    const wordsToRemove = ['diamond', 'diamonds', 'points'];
    const prizeTitle = item.name
      .toLowerCase()
      .split(' ')
      .filter(word => !wordsToRemove.includes(word))
      .join(' ');
  
    return (
      <div
        className="wheel-item"
        key={index}
        style={{
          '--item-nb': index,
          '--border-color': color,
          '--prize-type': isPrizeNone ? 'none' : isPrizeDiamond ? 'diamond' : isPrizePoints ? 'points' : 'item',
        }}
      >
        {isPrizeNone ? (
          <span style={{ fontSize: '16px', fontFamily: 'monospace', fontWeight: 'bolder'}}>Next Time!</span> 
        ) : isPrizeDiamond ? (
          <span style={{ fontSize: '20px', fontWeight: 'bolder'}}>
            {prizeTitle}
            <img src={diamondIconSrc} alt="diamond" width={28} style={{ verticalAlign: 'middle' }} />
          </span>
        ) : isPrizePoints ? (
          <span>
            {prizeTitle}
            <img src={coinIconSrc} alt="coin" width={28} style={{ verticalAlign: 'middle' }} /> 
          </span>
        ) : (
          <span>{item.name}</span>
        )}
      </div>
    );
  }, [colors]);

  const renderParticle = (particle) => {
    const style = {
      width: `${particle.size}px`,
      height: `${particle.size}px`,
      backgroundColor: particle.color,
      filter: `drop-shadow(0 0 10px ${particle.color}) drop-shadow(0 0 5px ${particle.color})`,
      borderRadius: '50%'
    };

    return <div style={style} />;
  };

  return (
    <WheelContainer>
      <div className="wheel-container">
        <div
          className={`wheel ${isSpinning ? "spinning" : ""}`}
          style={{ "--nb-item": PRIZES.length, "--selected-item": selectedItem }}
        >
          {PRIZES.map((item, index) => renderWheelItem({ item, index }))}
          <button
            className="spin-button-demo"
            onClick={selectItem}
            disabled={isSpinning}
          />
        </div>
        
        {particles.map((particle) => (
          <Particle
            key={particle.id}
            style={{
              '--tx': `${particle.tx}px`,
              '--ty': `${particle.ty}px`,
              animationDelay: `${particle.delay}s`,
              animationDuration: `${particle.duration}s`,
            }}
          >
            {renderParticle(particle)}
          </Particle>
        ))}
      </div>
      
      <SpinButton 
        startSpin={selectItem} 
        disabled={isSpinning || !userProfileInfo || userProfileInfo.diamonds < multiplier} 
      />
      
      {/* Multiplier button */}
      <MultiplierButton
        onClick={cycleMultiplier}
        disabled={isSpinning}
      >
        {multiplier}x
      </MultiplierButton>
    </WheelContainer>
  );
};

export default SpinWheelGame;
import React from 'react';
import { LinearProgress, Box } from '@mui/material';

const ProgressBar = ({ step }) => {
  const progressValue = (step / 5) * 100;

  return (
    <Box sx={{ width: '87vw', height: '1.5vh', position: 'absolute', top: 10, left: '50%', transform: 'translateX(-50%)', zIndex: 9 }}>
      <LinearProgress variant="determinate" value={progressValue} sx={{ height: '100%', borderRadius: '10px', '& .MuiLinearProgress-bar': { backgroundColor: step === 4 ? 'white' : 'royalblue' } }} />
    </Box>
  );
};

export default ProgressBar;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Link } from 'react-router-dom';
import AlertBadge from '../AlertBadge/AlertBadge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';

// Animation keyframes
const popIn = keyframes`
  0% { transform: scale(1) translateY(0); }
  50% { transform: scale(1.1) translateY(-28px); }
  75% { transform: scale(0.95) translateY(-25px); }
  100% { transform: scale(1) translateY(-25px); }
`;

// Styled components
const TabsContainer = styled(Box)(({ theme, blurEffect }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#02071cc7',
  backdropFilter: blurEffect ? 'blur(5px)' : 'none',
  borderRadius: '40px 40px 0 0',
  padding: '8px 24px',
  width: '85vw',
  height: '50px',
  margin: '0 auto',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 99,
  willChange: 'transform'
}));

const NavButton = styled(Button)(({ theme, active, label }) => ({
  flex: 1,
  borderRadius: 0,
  color: 'white',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 0',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'visible',

  '& .MuiSvgIcon-root': {
    fontSize: active ? 28 : 24,
    transform: active ? 'translateY(-4px)' : 'none',
    transition: 'all 0.3s ease'
  },

  ...(active && {
    backgroundColor: '#483d8b94',
    backdropFilter: 'blur(3px)',
    borderRadius: '16px',
    color: '#fff',
    animation: `${popIn} 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) forwards`,
    maxWidth: '77px',
    height: '66px',
    zIndex: 1,
    '&::before': {
      content: `"${label}"`,
      fontSize: '14px',
      fontFamily: 'Sora, sans-serif',
      position: 'absolute',
      bottom: -10,
      left: 0,
      width: '100%',
      height: '27px',
      backgroundColor: '#5151ff',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff'
    }
  }),

  '&:hover': {
    transform: active ? 'none' : 'scale(1.1)',
    backgroundColor: active ? '#483d8b94' : 'transparent',
  }
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
  flex: 1
});

// Tab configuration
const TABS = [
  { icon: <PetsIcon />, label: 'Home', link: '/' },
  { icon: <ListAltIcon />, label: 'Task', link: '/task' },
  { icon: <ShoppingBagIcon />, label: 'Shop', link: '/shop' }, 
  { icon: <EmojiEventsIcon />, label: 'Rewards', link: '/rewards' },
  { icon: <GroupIcon />, label: 'Referral', link: '/referrals' }
];

// Main component
const BottomTabs = memo(({ activeTab, blurEffect = false, taskAlert = false }) => {
  return (
    <TabsContainer blurEffect={blurEffect}>
      {TABS.map((tab, index) => (
        <StyledLink 
          to={tab.link} 
          key={tab.label}
        >
          <NavButton 
            active={index === activeTab} 
            label={tab.label}
          >
            {tab.icon}
            {taskAlert?.status && index === 1 && (
              <AlertBadge 
                pulseSpeed={1} 
                top={-6} 
                right={0} 
                size={17}
              >
                {taskAlert?.count || "!"}
              </AlertBadge>
            )}
          </NavButton>
        </StyledLink>
      ))}
    </TabsContainer>
  );
});

BottomTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  blurEffect: PropTypes.bool,
  taskAlert: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      status: PropTypes.bool,
      count: PropTypes.number
    })
  ])
};

BottomTabs.displayName = 'BottomTabs';

export default BottomTabs;
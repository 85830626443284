import React, { useState, useEffect, useCallback, memo } from 'react';
import { Box, Button, Typography, IconButton, Slider } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { taskApi } from '../../services/api';
import { useTonConnectUI } from '@tonconnect/ui-react';
import AlertBadge from '../AlertBadge/AlertBadge';
import { TASK_ICONS, REWARD_ICONS } from '../Task';

const TASK_CATEGORIES = {
  TELEGRAM: 'Telegram',
  X: 'X',
  YOUTUBE: 'Youtube',
  FARCASTER: 'Farcaster',
  REFERRAL: 'Referral',
  TON: 'TON'
};

const VERIFY_WAIT_TIME = 25; // seconds 

// Enhanced storage functions
const getStoredTransaction = (taskId) => {
  try {
    const stored = localStorage.getItem(`ton_tx_${taskId}`);
    if (!stored) return null;
    const data = JSON.parse(stored);
    return {
      timestamp: parseInt(data.timestamp),
      hash: data.hash,
      amount: data.amount
    };
  } catch {
    return null;
  }
};

const storeTransaction = (taskId, hash, amount) => {
  const data = {
    timestamp: Date.now(),
    hash: hash,
    amount: amount
  };
  localStorage.setItem(`ton_tx_${taskId}`, JSON.stringify(data));
};

const calculateRemainingTime = (timestamp) => {
  if (!timestamp) return 0;
  const elapsed = Math.floor((Date.now() - timestamp) / 1000);
  return Math.max(0, VERIFY_WAIT_TIME - elapsed);
};

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}m ${secs.toString().padStart(2, '0')}s`;
};

const PopupContainer = styled(Box)(({ theme, customHeight = null }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: customHeight || '55vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTopLeftRadius: '35px',
  borderTopRightRadius: '35px',
  borderTop: '5px solid orange',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));

const StyledTaskButton = styled(Button, {
  shouldForwardProp: prop => !['isActive', 'isWaiting'].includes(prop)
})(({ theme, isActive, isWaiting }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '7.5vh',
  width: '80%',
  padding: '12px 16px',
  backgroundColor: isActive ? 'rgb(4 75 255)' : isWaiting ? 'rgb(4 75 255)' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: 'inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3ax 1px #5529ec',
  textShadow: isActive ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'all 0.2s ease-in-out',
  cursor: isWaiting ? 'not-allowed' : 'pointer',
  fontSize: '17px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  opacity: isWaiting ? 0.7 : 1,
  filter: 'hue-rotate(300deg)',
  '&:hover': {
    transform: isWaiting ? 'none' : 'scale(1.02)',
    backgroundColor: 'rgb(232 4 255)',
  },
  '&:active': {
    transform: isWaiting ? 'none' : 'scale(0.98)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:disabled': {
    backgroundColor: '#666',
    cursor: 'not-allowed',
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#5529ec',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundImage: 'linear-gradient(90deg, #4a50ea, #8c6edf)',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#5529ec',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const AmountBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '15px',
  padding: '15px 20px',
  marginBottom: '15px',
  width: '80%',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(10px)',
}));

const ConfirmTaskPopup = memo(({ 
  task, 
  userId, 
  userAddress,
  open, 
  onClose, 
  setOpenConfetti,
  showSnackbar
}) => {
  const [tonConnectUI] = useTonConnectUI();
  const [taskStarted, setTaskStarted] = useState(false);
  const [canVerify, setCanVerify] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0.1);
  const [verifyCountdown, setVerifyCountdown] = useState(0);
  
  const isTonSupportTask = task?.category === TASK_CATEGORIES.TON && 
                          task?.title?.toLowerCase().includes('support');

  // Check stored transaction and start countdown when popup opens
  useEffect(() => {
    if (!open || !task?.category === TASK_CATEGORIES.TON) return;

    const storedTx = getStoredTransaction(task.taskId);
    if (storedTx) {
      setTaskStarted(true);
      setTransactionHash(storedTx.hash);
      const remaining = calculateRemainingTime(storedTx.timestamp);
      if (remaining > 0) {
        setCanVerify(false);
        setVerifyCountdown(remaining);
      } else {
        setCanVerify(true);
        setVerifyCountdown(0);
      }
    }
  }, [open, task?.taskId, task?.category]);

  // Handle countdown timer
  useEffect(() => {
    if (verifyCountdown > 0) {
      const timer = setInterval(() => {
        setVerifyCountdown(prev => {
          if (prev <= 1) {
            setCanVerify(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [verifyCountdown]);

  const handleAmountChange = useCallback((event, newValue) => {
    setDonationAmount(newValue);
  }, []);

  const handleStartTask = useCallback(async () => {
    if (task?.category === TASK_CATEGORIES.TON) {
      if (!userAddress) {
        showSnackbar('Please connect your wallet first', 'error');
        return;
      }
  
      try {
        const amount = isTonSupportTask ? 
          Math.floor(donationAmount * 1000000000) : 
          Math.floor(task.amount * 1000000000);
  
        const transaction = {
          validUntil: Date.now() + 5 * 60 * 1000,
          messages: [{
            address: task.targetAddress,
            amount: amount.toString(),
          },
         ],
        };
  
        const result = await tonConnectUI.sendTransaction(transaction);
        
        if (result?.boc) {
          setTransactionHash(result.boc);
          setTaskStarted(true);
          setCanVerify(false);
          setVerifyCountdown(VERIFY_WAIT_TIME);
          // Store transaction details
          storeTransaction(task.taskId, result.boc, amount);
          showSnackbar('Submitting transaction! Please wait 1 minutes before verifying.', 'info', true);
        }
      } catch (error) {
        console.error('Transaction error:', error);
        showSnackbar(error?.message || 'Failed to send transaction', 'error');
      }
      return;
    }

    if (!task?.link && task?.category !== TASK_CATEGORIES.REFERRAL) {
      showSnackbar('Invalid task link', 'error');
      return;
    }
  
    const taskLink = task.category === TASK_CATEGORIES.REFERRAL ? 
      inviteSharingText() : task.link;
    setTaskStarted(true);
    window.open(taskLink, '_blank');
  }, [task, tonConnectUI, showSnackbar, donationAmount, isTonSupportTask, userAddress]);

  const handleTaskVerification = useCallback(async () => {
    if (!task?.taskId || !userId) {
      showSnackbar('Loading task data, please try again.', 'info');
      return;
    }

    if (task?.category === TASK_CATEGORIES.TON) {
      if (!userAddress) {
        showSnackbar('Please connect your wallet first', 'error');
        return;
      }

      if (verifyCountdown > 0) {
        showSnackbar(`Please wait ${formatTime(verifyCountdown)} before verifying`, 'info');
        return;
      }

      const storedTx = getStoredTransaction(task.taskId);
      if (!storedTx?.hash) {
        showSnackbar('No transaction found. Please send transaction first', 'error');
        return;
      }
    }

    try {
      const response = await taskApi.checkTask(
        userId, 
        task.taskId,
        task?.category === TASK_CATEGORIES.TON ? {
          userAddress,
          transactionHash: transactionHash,
          amount: getStoredTransaction(task.taskId)?.amount
        } : undefined
      );
          
      if (!response?.data?.success) {
        throw new Error(response?.data?.error || 'Verification failed');
      }
      
      setOpenConfetti(true);
      setTaskStarted(false);
      onClose();
      showSnackbar('Task completed successfully!', 'success');
      
      if (task?.category === TASK_CATEGORIES.TON) {
        localStorage.removeItem(`ton_tx_${task.taskId}`);
      }
    } catch (error) {
      console.error('Error verifying task:', error);
      showSnackbar(error?.response?.data?.error || 'Failed to verify task', 'error');
    }
  }, [task, userId, userAddress, verifyCountdown, transactionHash, showSnackbar, onClose, setOpenConfetti]);

  const renderVerifyButton = () => {
    let buttonText = 'Complete Task First';
    let isButtonEnabled = false;

    if (task?.category === TASK_CATEGORIES.TON) {
      if (taskStarted && transactionHash) {
        if (verifyCountdown > 0) {
          buttonText = `Wait ${formatTime(verifyCountdown)}`;
          isButtonEnabled = false;
        } else {
          buttonText = 'Verify Task';
          isButtonEnabled = true;
        }
      } else {
        const storedTx = getStoredTransaction(task.taskId);
        if (storedTx?.hash) {
          setTransactionHash(storedTx.hash);
          setTaskStarted(true);
          buttonText = 'Verify Task';
          isButtonEnabled = true;
        } else {
          buttonText = 'Send Transaction First';
          isButtonEnabled = false;
        }
      }
    } else if (taskStarted) {
      buttonText = 'Verify Task';
      isButtonEnabled = true;
    } else if (task?.category === TASK_CATEGORIES.REFERRAL) {
      buttonText = 'Verify Task';
      isButtonEnabled = true;
    }

    return (
      <StyledTaskButton 
        onClick={handleTaskVerification}
        disabled={!isButtonEnabled || verifyCountdown > 0}
        isActive={isButtonEnabled && verifyCountdown === 0}
      >
        {buttonText}
        {isButtonEnabled && verifyCountdown === 0 && (
          <AlertBadge pulseSpeed={1} top={-4} right={0} size={17} />
        )}
      </StyledTaskButton>
    );
  };

  const inviteSharingText = () => {
    const inviteLink = `https://t.me/FoxiTips_bot/launch?startapp=${userId}`;
    const message = `Join Foxi mini-app now! 🦊
Get daily budgets and enjoy your free tipping.\n\nClick the link below to get started.\n${inviteLink}`;
    
    const encodedText = encodeURIComponent(message);
    return `https://t.me/share/url?url=${encodedText}`;
  };

  return (
    <PopupContainer 
      customHeight={task?.category === TASK_CATEGORIES.TON ?
          isTonSupportTask && taskStarted ? '56vh' : '78vh' 
          : '55vh'}
      sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}
    >
      <Box display="flex" justifyContent="space-between" mt={2} mr={2}>
        <Typography variant="h5" fontFamily="Poppins-Medium, sans-serif" fontSize="20px" ml={2}>
          {task?.category} Task
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
  
      <Box display="flex" gap="5px" flexDirection="column" justifyContent="center" alignItems="center" position="relative" bottom="20px">
        <img 
          src={task?.icon || TASK_ICONS[task?.category]} 
          alt={task?.type} 
          width={task?.icon? "100px" : "110px"} 
          height={task?.icon? "100px" : "110px"} 
          style={{ 
            borderRadius: '35%', 
            marginLeft: task?.icon ? '7px' : 0, 
            position: 'relative', 
            top: task?.icon ? '7px' : 0 
          }} 
        />
        <Box display="flex" flexDirection="column" gap="5px" position="relative" bottom="8px" top={task?.icon ? '5px' : 0}>
          <Typography variant="h6" fontFamily="Poppins-Medium, sans-serif" fontSize={taskStarted ? '14px' : '20px'} ml={2}>
            {task?.title.length > 35 ? `${task?.title.slice(0, 35)}...` : task?.title}
          </Typography>
          <Box display="flex" gap="5px" justifyContent="center" alignItems="center" mt={task?.icon ? 0 : 0.25}>
            {task?.diamonds > 0 && (
              <>
                <img src={REWARD_ICONS.DIAMOND} alt="Diamond" style={{ width: '18px', marginRight: '2px' }} />
                {task.diamonds}&nbsp;&nbsp;
              </>
            )}
            <img src={REWARD_ICONS.COIN} alt="Points" style={{ width: task?.diamonds > 0 ? '16px' : '17px', marginRight: '2px' }} />
            {task?.points}
          </Box>
        </Box>
  
        {task?.category === TASK_CATEGORIES.TON && transactionHash && (
          <Typography variant="body2" sx={{ mt: 1, opacity: 0.8 }}>
            Transaction Hash: {transactionHash.slice(0, 8)}...{transactionHash.slice(-8)}
          </Typography>
        )}
  
        {isTonSupportTask && !taskStarted && (
          <AmountBox>
            <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight="bold" mb={1}>
              Donation Amount: {donationAmount.toFixed(3)} TON
            </Typography>
            <StyledSlider
              value={donationAmount}
              onChange={handleAmountChange}
              min={0.1}
              max={10}
              step={0.001}
              valueLabelDisplay="auto"
              valueLabelFormat={value => `${value.toFixed(3)} TON`}
              marks={[
                { value: 0.1, label: '0.1' },
                { value: 5, label: '5' },
                { value: 10, label: '10' }
              ]}
            />
            <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 1, opacity: 0.7 }}>
              Slide to adjust donation amount
            </Typography>
          </AmountBox>
        )}
  
        {task?.category === TASK_CATEGORIES.TON && (
          <Typography 
            variant="body2" 
            sx={{ 
              mt: 1, 
              textAlign: 'center',
              color: verifyCountdown > 0 ? '#ffa726' : '#4caf50',
              fontWeight: 'bold'
            }}
          >
            {verifyCountdown > 0 
              ? `Verification available in: ${formatTime(verifyCountdown)}`
              : taskStarted ? 'You can verify the task now!' : ''}
          </Typography>
        )}
      </Box>
  
      <Box 
        display="flex" 
        gap="8px"
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        width="100%" 
        height="30%" 
        position="relative" 
        bottom={task?.category === TASK_CATEGORIES.TON ? '7vh' : '0vh'}
      >
        <StyledTaskButton 
          onClick={handleStartTask}
          disabled={taskStarted}
        >
          {task?.category === TASK_CATEGORIES.TON ? 
            (taskStarted ? 'Transaction Sent' : isTonSupportTask ? `Donate ${donationAmount} TON` : 'Send Transaction') :
            (task?.category === TASK_CATEGORIES.REFERRAL ? 
              'Invite Friends' : 
              (taskStarted ? 'Task Started' : 'Start Task')
            )
          }
        </StyledTaskButton>
        {renderVerifyButton()}
      </Box>
    </PopupContainer>
  );
});

ConfirmTaskPopup.propTypes = {
  task: PropTypes.shape({
    taskId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    link: PropTypes.string,
    points: PropTypes.number.isRequired,
    diamonds: PropTypes.number,
    verificationKey: PropTypes.string,
    targetAddress: PropTypes.string,
    amount: PropTypes.number
  }),
  userId: PropTypes.string.isRequired,
  userAddress: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setOpenConfetti: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

ConfirmTaskPopup.displayName = 'ConfirmTaskPopup';

export default ConfirmTaskPopup;
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import FoxiTeacher from '../../assets/images/characters/foxi-teacher.png';
import BG4 from '../../assets/images/home-elements/not-tap-tap/bg-4.jpg';
import Foxi from '../../assets/images/home-elements/not-tap-tap/foxi-teacher-2.png';

const PopupContainer = styled(Box)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '70vw',
  maxWidth: '360px',
  // background: 'repeating-radial-gradient(#6301cb5c 0%, #560ca0cc 100px)',
  background: 'rgba(86, 12, 160, 0.65)',
  // background: `url(${BG4})`,
  backgroundSize: '100vw',
  backgroundPosition: '-40px 300px',
  borderRadius: '40px',
  border: '5px solid #FFA500',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 999,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)',
  transition: 'all 0.3s',
  willChange: 'transform, opacity',
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: '10px',
  top: '10px',
  color: 'white'
});


const watchTutorialButton = {
  width: '90%',
  height: '50px',
  fontSize: '18px',
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  filter: 'hue-rotate(300deg)',
  margin: '0 auto',
  mt: '10px'
};

const closeButton = {
  width: '90%',
  height: '50px',
  fontSize: '18px',
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  filter: 'hue-rotate(75deg)',
  margin: '0 auto',
  mt: '10px'
};


const NotTapTapPopup = memo(({ open, onClose, StyledButton }) => {
  const onWatchVideo = () => {
    window.open('https://youtu.be/FknuwRawwFk', '_blank');
  };

  return (
    <PopupContainer sx={{ opacity: open ? 1 : 0, transform: open ? 'translate(-50%, -50%)' : 'translate(-160%, 50%)' }}>
      <CloseButton onClick={onClose} aria-label="close" sx={{ zIndex: 999 }}>
        <CloseIcon fontSize="large" />
      </CloseButton>

      {/* <Box
        component="img"
        src={Foxi}
        alt="Foxi Teacher"
        sx={{ 
          width: '115%',
          height: '200px',
          position: 'relative',
          top: '-20px',
          marginBottom: '10px',
          borderRadius: '33px 33px 0px 0px'
        }}
      /> */}

      <Typography
        sx={{
          color: '#FF69B4',
          fontSize: '28px',
          fontWeight: 'bold',
          fontFamily: 'sora, sans-serif',
          textAlign: 'center',
          mt: '30px'
        }}
      >
        Don't Tap me!
      </Typography>

      <Typography
        sx={{
          color: 'white',
          fontSize: '20px',
          fontFamily: 'sora, sans-serif',
          textAlign: 'center',
          marginBottom: '15px'
        }}
      >
        Foxi is a Tip bot
      </Typography>

      <StyledButton onClick={onWatchVideo} sx={watchTutorialButton}>
        Watch tutorial video
      </StyledButton>
      {/* <StyledButton onClick={onClose} sx={closeButton}>
         Close
      </StyledButton> */}
    </PopupContainer>
  );
});

NotTapTapPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

NotTapTapPopup.displayName = 'NotTapTapPopup';

export default NotTapTapPopup;